import * as React from 'react';

function SvgActions(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={3} y={7} width={2} height={2} rx={0.5} fill="currentColor" />
      <rect x={7} y={7} width={2} height={2} rx={0.5} fill="currentColor" />
      <rect x={11} y={7} width={2} height={2} rx={0.5} fill="currentColor" />
    </svg>
  );
}

export default SvgActions;
